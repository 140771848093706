import React from 'react';
import { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';

function Header() {
    const location = useLocation();  
    const [expanded, setExpanded] = useState(false);

    const handleLinkClick = () => {
        // Solo colapsar el Navbar si está actualmente expandido
        if (expanded) {
            setExpanded(false);
        }
    };

    const toggleNavbar = () => {
        setExpanded(!expanded);
    };
    
    return (
        <Navbar bg="#0a0b0f" expand="sm" expanded={expanded} onToggle={toggleNavbar}>

                <a href="https://youtu.be/Eyjj8BgsBGU?si=dzpFmxnsETMucp13" target="_blank" rel="noopener noreferrer" className="navbar-brand">
                <img src="\images\me.webp" width="48" height="48" className="d-inline-block align-top myImage" alt="" />
                <div className='header_brand_bio'><span className='SpanName navbar-text'>Jeremías Giorgi</span></div>
                </a>
            
                <Navbar.Toggle className='collapseButtonNav navbar-dark'/>
                <Navbar.Collapse> 
            <Nav style={{ marginLeft: 'auto' }}>
                <LinkContainer to="/" onClick={handleLinkClick}>
                    <Nav.Link active={location.pathname === '/'}>home</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/about" onClick={handleLinkClick}>
                    <Nav.Link active={location.pathname === '/about'}>about</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/hobbies" onClick={handleLinkClick}>
                    <Nav.Link active={location.pathname === '/hobbies'}>hobbies</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/contact" onClick={handleLinkClick}>
                    <Nav.Link active={location.pathname === '/contact'}>contact</Nav.Link>
                </LinkContainer>
            </Nav>
             </Navbar.Collapse>
        </Navbar>
    );
}

export default Header;