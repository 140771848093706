import React from 'react';

function Footer() {
    return (
        
        <footer className="m-0 py-3 footerCustom">
        &copy; {new Date().getFullYear()} JEREMIAS
        </footer>
        
    );
}

export default Footer;