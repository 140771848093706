import React from 'react';

function Hobbies() {
    return (
        <div className="principalHobbies">
                
            <div className='LeftHobbieContainer'>
                <div className='imageHobbie'>
                    <img src="\images\rocket_team_2-3.webp" alt="experimental rocketry"/>
                </div>
                
                <div className='leftTextHobbies'>
                    <h1>EXPERIMENTAL ROCKETRY</h1>
                    <hr className='leftHobbieHr'/>
                    <p>One of the most challenging, educational and exciting Hobbies I can do. this great hobby started in a small coffee shop at the beginning of 2018 together with Laureano, to this day, we have a flight computer and other very interesting things, and it's all <a href="https://github.com/laureano-arcanio/arduino-rocket-computer" className='aOpenSource' target="_blank" rel="noopener noreferrer">OpenSource</a>!</p>
                </div>
                
            </div>

            <div className='rightHobbieContainer'>
                <div className='imageHobbie'>
                    <img src="\images\DSC_0416.webp" width="400" alt="Cat Under Rain"/>
                </div>
                
                <div className='leftTextHobbies'>
                    <h1>PHOTOGRAPHY</h1>
                    <hr className='rightHobbieHr'/>
                    <p>Capturing special and unique moments with my camera is something I love, through photography I can express what words can't, that's something inexplicable. You can <a href="https://www.flickr.com/photos/158245744@N05/" className='aPhotos' target="_blank" rel="noopener noreferrer">see my photos here</a>!</p>
                </div>
                
            </div>

            <div className='LeftHobbieContainer'>
                <div className='imageHobbie'>
                    <img src="\images\IMG_20210421_181930.webp" width="400" alt="Chess Table"/>
                </div>
                
                <div className='leftTextHobbies'>
                    <h1>CHESS</h1>
                    <hr className='leftHobbieHr'/>
                    <p>Yes, I play chess. I started watching videos on <a href="https://www.youtube.com/@Reydama"className='aChess' target="_blank" rel="noopener noreferrer">Youtube</a> on how to learn chess or reviews of games from GM's. then my grandmother gave me a chessboard with a magnet. from that day on I played late at night and in the evening. Days later I bought a book about chess, that made me want to study and learn chess in depth. I managed to learn strategies and vision in advance of the game. It was totally worth it!</p>
                </div>
                
            </div>

            <div className='rightHobbieContainer'>
                <div className='imageHobbie'>
                    <img src="\images\M201123.webp" width="400" alt="Airsoft Cordoba"/>
                </div>
                
                <div className='leftTextHobbies'>
                    <h1>AIRSOFT</h1>
                    <hr className='rightHobbieHr'/>
                    <p>Airsoft is a physical, playful and recreational activity, based on the fulfillment of objectives, thanks to this sport I learned strongly to work as a team to fulfill the activities and objectives that we wanted to achieve.</p>
                </div>
                
            </div>


        </div>
    );
}

export default Hobbies;

