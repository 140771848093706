import React from "react";
import "../../CssStyles/ProjectCard.css";

function ProjectCard({ Project }) {
  const handleCardClick = () => {
    window.open(Project.externalUrl, "_blank");
  };
  return (
    <li
      className="liCards"
      style={{ backgroundImage: `url(${Project.ImgUrl})` }}
      onClick={handleCardClick}
    >
      <div className="cardContainer">
        <h1>{Project.title}</h1>
        <p>{Project.description}</p>
        <span className="tecnologiesCardContainer">
          {Project.tecnologies.map((tech) => (
            <span className="tecnologiesCards" key={tech.id}>
              <img src={tech.icon} alt={tech.alt} />
            </span>
          ))}
        </span>
      </div>
    </li>
  );
}

export default ProjectCard;
