import React from "react";

function Contact() {
  return (
    <div
      className="container mt-5 mb-5 text-white ml-auto mr-auto"
      data-bs-theme="dark"
      style={{ maxWidth: "600px", height: "58vh"}}
    >
      <div className="contactContainer">
        <h2>¿Looking for a launch?</h2>
        <p>Together we can make that rocket liftoff</p>
        <ul
          className="iconContainer mt-4 mb-4"
          style={{ justifyContent: "center" }}
        >
          <li style={{ margin: "0 1rem" }}>
            <a
              href="https://www.linkedin.com/in/jerem%C3%ADas-giorgi-48924828b/"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                data-supported-dps="24x24"
                fill="currentColor"
                className="mercado-match"
                width="24"
                height="24"
                focusable="false"
              >
                <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
              </svg>
            </a>
          </li>
          <li style={{ margin: "0 1rem" }}>
            <a href="mailto:jeremiasgiorgi@gmail.com">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon-mail"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                <path d="M3 7l9 6l9 -6" />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Contact;