import React from "react";
import SkillsData from "../DataLocalStorage/Skills.json";
import EducationData from "../DataLocalStorage/Education.json";
import ExperienceData from "../DataLocalStorage/Experience.json";
import SkillCard from "./Cards/skillsCard";
import "../CssStyles/AboutMe.css";

function About() {
  return (
    <div className="aboutContainer">
      <div className="WhoImI">
        <h2>
          about(<span className="myNameSpan">Jeremías</span>)
        </h2>
        <ul className="iconContainer">
          <li className="iconLi">
            <a
              href="https://github.com/jeremiasgiorgi"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                className="icon-github"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12.006 2a9.847 9.847 0 0 0-6.484 2.44 10.32 10.32 0 0 0-3.393 6.17 10.48 10.48 0 0 0 1.317 6.955 10.045 10.045 0 0 0 5.4 4.418c.504.095.683-.223.683-.494 0-.245-.01-1.052-.014-1.908-2.78.62-3.366-1.21-3.366-1.21a2.711 2.711 0 0 0-1.11-1.5c-.907-.637.07-.621.07-.621.317.044.62.163.885.346.266.183.487.426.647.71.135.253.318.476.538.655a2.079 2.079 0 0 0 2.37.196c.045-.52.27-1.006.635-1.37-2.219-.259-4.554-1.138-4.554-5.07a4.022 4.022 0 0 1 1.031-2.75 3.77 3.77 0 0 1 .096-2.713s.839-.275 2.749 1.05a9.26 9.26 0 0 1 5.004 0c1.906-1.325 2.74-1.05 2.74-1.05.37.858.406 1.828.101 2.713a4.017 4.017 0 0 1 1.029 2.75c0 3.939-2.339 4.805-4.564 5.058a2.471 2.471 0 0 1 .679 1.897c0 1.372-.012 2.477-.012 2.814 0 .272.18.592.687.492a10.05 10.05 0 0 0 5.388-4.421 10.473 10.473 0 0 0 1.313-6.948 10.32 10.32 0 0 0-3.39-6.165A9.847 9.847 0 0 0 12.007 2Z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </li>
          <li className="iconLi">
            <a
              href="https://www.linkedin.com/in/jerem%C3%ADas-giorgi-48924828b/"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                data-supported-dps="24x24"
                fill="currentColor"
                className="mercado-match"
                width="24"
                height="24"
                focusable="false"
              >
                <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
              </svg>
            </a>
          </li>
          <li className="iconLi">
            <a href="mailto:jeremiasgiorgi@gmail.com">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon-mail"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                <path d="M3 7l9 6l9 -6" />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="/documents/JeremiasCV.pdf"
              target="_blank"
              className="buttonCv"
            >
              CV
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-tabler icons-tabler-outline icon-tabler-cloud-download"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" color="black" />
                <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4" color="black" />
                <path d="M12 13l0 9" color="black" />
                <path d="M9 19l3 3l3 -3" color="black" />
              </svg>
            </a>
          </li>
        </ul>
        <div>
          <p className="AboutP">
            Hi, I'm a self-taught nerd Front-end Developer who loves Music,
            Bikes, Games, Thunder Storms & Italian Food!
          </p>
          <p className="AboutP">
            Passionate about learning more every day and being able to improve
            myself to help humans!
          </p>
          <p className="pComment">&#x2F;&#x2F; Junior Front-End Dev</p>
          <div className="skillsContainer">
            <h3>Skills</h3>
            <ul>
              {SkillsData.map((data) => (
                <SkillCard key={data.id} skills={data.habilidades} />
              ))}
            </ul>
            <h3>Experience</h3>
            <ul>
              {ExperienceData.map((experiencia) => (
                <li key={experiencia.id}>
                  <span>{experiencia.job}</span>
                  <p>
                    {experiencia.name} <br /> {experiencia.year}
                  </p>
                </li>
              ))}
            </ul>
            <h3>Education</h3>
            <ul>
              {EducationData.map((educacion) => (
                <li key={educacion.id}>
                  <p>
                    <span>{educacion.name}</span> <br />
                    {educacion.year}
                  </p>
                </li>
              ))}
            </ul>
          </div>

          <p className="AboutP">
            At 14 years old and thanks to a family member, I was able to get
            started in this exciting world. It all started with the idea of
            building a{" "}
            <a
              href="https://reprap.org/wiki/RepRap"
              className="aRepRap"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              3D printer assembled
            </a>{" "}
            from waste. It worked!
          </p>
          <p className="AboutP">
            I love capturing moments with my analog and digital cameras, thanks
            to this I greatly improved my skills in Photoshop, Lightroom,
            Blender and many other design & art software.
          </p>
          <p className="pComment">&#x2F;&#x2F; see you soon!</p>
        </div>
      </div>
    </div>
  );
}

export default About;
