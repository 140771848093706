import React from "react";

function SkillCard({ skills }) {
  return (
    <li>
      {skills.map((habilidades) => (
        <p key={habilidades.id}>{habilidades.skill}</p>
      ))}
    </li>
  ); 
}

export default SkillCard;
